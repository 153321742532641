export const LOCALSTORAGE = {
  token: '@darilaslovenije/token',
};

export const _COUPONSTATUS = {
  valid: 'valid',
  invalid: 'invalid',
  used: 'used',
  notexisting: 'notexisting',
  expired: 'expired',
};

export const _USERROLES = {
  admin: 'administrator',
};
