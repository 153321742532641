<div class="check-coupon-container">
  <form
    class="row m-0 justify-content-center"
    [formGroup]="checkCouponForm"
    (ngSubmit)="checkCoupon()"
  >
    <div class="form-group col-10 col-md-8 col-lg-4 mb-0">
      <label for="couponNoInput">Iskanje bona</label>
      <input
        type="text"
        placeholder="Številka darilnega bona"
        formControlName="couponNoInput"
        class="form-control"
      />
    </div>
    <div class="btn-container col-6 col-md-2 col-lg-1">
      <button [disabled]="loading" class="btn btn-primary">Iskanje</button>
    </div>
  </form>
  <div class="text-center mt-4" *ngIf="loading">
    <span class="spinner-border spinner-border-sm mr-1"></span>
  </div>
  <ng-container *ngIf="couponValidity" [ngSwitch]="couponValidity.status">
    <div class="coupon-status-container">
      <ng-container *ngSwitchCase="_COUPONSTATUS.valid">
        <div class="valid">Kupon je veljaven</div>
        <div>
          Veljaven do: {{ couponValidity.validEndDate | date: "dd. MM. yyyy" }}
        </div>
        <button
          [disabled]="loading"
          (click)="useCoupon()"
          class="btn btn-primary"
        >
          Vnovči kupon
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="_COUPONSTATUS.invalid">
        <div>
          <p>Kupon ne obstaja</p>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="_COUPONSTATUS.used">
        <div>Kupon je porabljen</div>
        <div>
          Datum porabe: {{ couponValidity.usageDate | date: "dd. MM. yyyy" }}
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="_COUPONSTATUS.expired">
        <div>Kupon je porabljen</div>
        <div>
          Datum poteka: {{ couponValidity.validEndDate | date: "dd. MM. yyyy" }}
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
