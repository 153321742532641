import { Injectable } from '@angular/core';
import { ReplaySubject, from, Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { UserApiService } from '../@shared/api/user.api';
import { WpUserRequest } from '../@shared/types/user.types';
import { LOCALSTORAGE, _USERROLES } from '../@shared/consts/app.consts';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class AuthService {
  isLoggedIn = new Subject<boolean>();
  tokenSubject: ReplaySubject<string> = new ReplaySubject<string>(1);
  userData: any;
  constructor(
    private router: Router,
    private userApiService: UserApiService,
    private jwtHelper: JwtHelperService
  ) {}

  isTokenValid(): boolean {
    return !this.jwtHelper.isTokenExpired(this.getTokenFromLocalStorage());
  }

  isUserAdmin(): boolean {
    const decoded = this.jwtHelper.decodeToken(this.getTokenFromLocalStorage());
    if (decoded && decoded.data?.user && decoded.data?.user.role) {
      const roles: string[] = decoded.data.user.role;
      return roles.includes(_USERROLES.admin);
    }
    return false;
  }

  saveTokenToLocalStorage(token: string) {
    localStorage.setItem(LOCALSTORAGE.token, token);
  }

  getTokenFromLocalStorage(): string {
    return localStorage.getItem(LOCALSTORAGE.token);
  }

  deleteTokenFromLocalStorage() {
    localStorage.removeItem(LOCALSTORAGE.token);
    this.isLoggedIn.next(false);
  }

  decodeToken() {
    return this.jwtHelper.decodeToken(this.getTokenFromLocalStorage());
  }

  async login(username: string, password: string): Promise<WpUserRequest> {
    try {
      const user = await this.userApiService
        .login(username, password)
        .toPromise();
      return user;
    } catch (error) {
      return null;
    }
  }

  async logout() {
    this.deleteTokenFromLocalStorage();
    this.isLoggedIn.next(false);
    this.router.navigate(['/login']);
  }

  loggedInNext() {
    this.isLoggedIn.next(true);
  }
}
