import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuardService } from './@core/admin-guard.service';
import { AuthGuardService } from './@core/auth-guard.service';
import { UnauthGuardService } from './@core/unauth-guard.service';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginPageModule),
    canActivate: [UnauthGuardService],
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [AdminGuardService],
  },
  {
    path: 'check-coupon',
    loadChildren: () =>
      import('./check-coupon/check-coupon.module').then(
        (m) => m.CheckCouponModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: '**',
    redirectTo: '/login',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
