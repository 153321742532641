import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CouponApiService } from '../../api/coupon.api';
import { _COUPONSTATUS } from '../../consts/app.consts';
import { CouponValidity } from '../../types/coupon.types';

@Component({
  selector: 'app-check-coupon',
  templateUrl: 'check-coupon.component.html',
  styleUrls: ['./check-coupon.component.scss'],
})
export class CheckCouponComponent implements OnInit {
  checkCouponForm: FormGroup;
  submitted = false;
  loading = false;
  couponValidity: CouponValidity;
  _COUPONSTATUS = _COUPONSTATUS;
  constructor(
    private fb: FormBuilder,
    private couponApiService: CouponApiService
  ) {}

  ngOnInit() {
    this.initForm();
  }

  get couponNoInput() {
    return this.checkCouponForm.get('couponNoInput');
  }

  initForm() {
    this.checkCouponForm = this.fb.group({
      couponNoInput: [
        '',
        Validators.compose([Validators.required, Validators.minLength(4)]),
      ],
    });
  }

  checkCoupon() {
    this.submitted = true;
    this.loading = true;
    this.couponApiService
      .checkCouponValidity(this.couponNoInput.value)
      .subscribe((res) => {
        this.submitted = false;
        this.loading = false;
        this.couponValidity = res;
      });
  }

  useCoupon() {
    console.log(this.couponValidity);
    this.couponApiService.useCoupon(this.couponValidity.couponNumber).subscribe(
      (r) => {
        console.log(r);
        this.checkCoupon();
      },
      (err) => {
        this.checkCoupon();
      }
    );
  }
}
