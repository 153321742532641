<ng-container>
  <div class="modal-header">
    <h4>{{ coupon.productName }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="content-header">
      <div class="d-flex justify-content-between">
        <div
          *ngFor="let option of detailsOptions; let i = index"
          class="option-title opt-{{ i }}"
          [ngClass]="{ selected: option.key === selectedOption?.key }"
          (click)="optionClick(option)"
        >
          {{ option.label }}
        </div>
      </div>
      <div
        class="content-header-actions-container d-flex justify-content-between"
        *ngIf="selectedOption && selectedOption.key !== 'prenos'"
      >
        <input
          class="input-option"
          [value]="selectedOption.inputVal"
          [type]="selectedOption.type"
          [placeholder]="selectedOption.placeholder"
          (input)="optionInputChange($event.target.value)"
        />
        <button
          class="btn btn-primary"
          (click)="optionConfirmClick()"
          [disabled]="!selectedOption.valid"
        >
          Potrdi
        </button>
      </div>
    </div>
    <div
      class="content-container"
      [innerHTML]="coupon.productDescription"
    ></div>
  </div>
</ng-container>
