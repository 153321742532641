import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Coupon, CouponSample, CouponValidity } from '../types/coupon.types';
import { WpUserRequest } from '../types/user.types';

@Injectable({ providedIn: 'root' })
export class CouponApiService implements CouponApiService {
  constructor(private http: HttpClient) {}

  createCoupon(productId: number): Observable<any> {
    return this.http.post(
      `${environment.api}/shop/createCoupon`,
      {
        productId,
      },
      {
        responseType: 'blob',
      }
    );
  }

  getSampleCoupon(productId: number): Observable<any> {
    return this.http.get(
      `${environment.api}/shop/getSampleCoupon/${productId}`,
      {
        responseType: 'blob',
      }
    );
  }

  checkCouponValidity(couponNo: string): Observable<CouponValidity> {
    return this.http.get<CouponValidity>(
      `${environment.api}/checkCoupon/${couponNo}`,
      {}
    );
  }

  sendEmail(couponNo: string, email: string): Observable<any> {
    return this.http.put<any>(
      `${environment.api}/shop/coupon/${couponNo}/resendEmail`,
      { email }
    );
  }

  extendCoupon(couponNo: string, days: number): Observable<any> {
    return this.http.put(`${environment.api}/shop/extend/${couponNo}`, {
      days,
    });
  }

  useCoupon(couponNo: string): Observable<any> {
    return this.http.put<boolean>(
      `${environment.api}/useCoupon/${couponNo}`,
      {}
    );
  }

  deleteCoupon(couponNo: string): Observable<any> {
    return this.http.put<boolean>(
      `${environment.api}/deleteCoupon/${couponNo}`,
      {}
    );
  }

  allCoupons(): Observable<Coupon[]> {
    return this.http.get<Coupon[]>(`${environment.api}/coupons`).pipe(
      map((coupons) => {
        coupons.sort((a, b) => {
          const d1 = Date.parse(a.validEndDate);
          const d2 = Date.parse(b.validEndDate);
          return d2 - d1;
        });
        return coupons;
      })
    );
  }

  allWpCoupons(): Observable<CouponSample[]> {
    return this.http.get<CouponSample[]>(
      `${environment.api}/allCouponProducts`
    );
  }

  downloadCoupon(couponNo: string): Observable<any> {
    return this.http.get(`${environment.api}/coupon/${couponNo}/download`, {
      responseType: 'blob',
    });
  }

  login(username: string, password: string): Observable<WpUserRequest> {
    return this.http.post<WpUserRequest>(
      `${environment.wordpressUrl}/jwt-auth/v1/token`,
      {
        username,
        password,
      }
    );
  }
}
