import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { CouponApiService } from '../../api/coupon.api';
import { Coupon } from '../../types/coupon.types';

@Component({
  selector: 'app-coupon-details-modal',
  templateUrl: 'coupon-details-modal.component.html',
  styleUrls: ['./coupon-details-modal.component.scss'],
})
export class CouponDetailsModalComponent implements OnInit {
  @Input()
  coupon: Coupon;
  detailsOptions = [
    {
      key: 'podaljsaj',
      type: 'number',
      placeholder: 'Podaljšaj za število dni',
      inputVal: null,
      label: 'PODALJŠAJ',
      valid: false,
    },
    {
      key: 'poslji',
      type: 'string',
      placeholder: 'Email (prazno za obstoječega kupca)',
      inputVal: '',
      label: 'POŠLJI',
      valid: true,
    },
    {
      key: 'prenos',
      type: '',
      placeholder: '',
      label: 'PRENOS',
      valid: true,
    },
    {
      key: 'preklic',
      type: 'string',
      placeholder: 'vnos PREKLIC za preklic bona',
      inputVal: '',
      label: 'PREKLIC',
      valid: false,
    },
  ];
  selectedOption: {
    key: string;
    type: string;
    placeholder: string;
    inputVal: string;
    label: string;
    valid: boolean;
  };
  headerDetailedViewOpen = false;
  constructor(private modal: NgbModal, private couponApi: CouponApiService) {}

  ngOnInit() {}

  dismiss() {
    this.modal.dismissAll();
  }

  optionClick(option) {
    this.selectedOption = option === this.selectedOption ? null : option;
    if (option.key === 'prenos') {
      this.couponApi.downloadCoupon(this.coupon.couponNumber).subscribe((r) => {
        var downloadURL = window.URL.createObjectURL(r);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = `${this.coupon.couponNumber}.pdf`;
        link.click();
      });
    }
  }
  optionInputChange(val: string) {
    switch (this.selectedOption.key) {
      case 'podaljsaj':
        if (
          val.length > 0 &&
          !isNaN(Number(val)) &&
          val !== null &&
          val !== undefined
        ) {
          this.selectedOption.inputVal = val;
          this.selectedOption.valid = true;
        } else {
          this.selectedOption.valid = false;
        }
        break;
      case 'poslji':
        this.selectedOption.inputVal = val;
        this.selectedOption.valid = val.length === 0 || this.validateEmail(val);
        break;
      case 'preklic':
        this.selectedOption.valid = val === 'PREKLIC';
        this.selectedOption.inputVal = val;
        break;
      default:
        break;
    }
  }

  optionConfirmClick() {
    switch (this.selectedOption.key) {
      case 'podaljsaj':
        const days = Number(this.selectedOption.inputVal);
        this.couponApi
          .extendCoupon(this.coupon.couponNumber, days)
          .subscribe((updatedCoupon) => {
            if (updatedCoupon) {
              this.dismiss();
            }
          });
        break;
      case 'poslji':
        this.couponApi
          .sendEmail(this.coupon.couponNumber, this.selectedOption.inputVal)
          .subscribe(() => {
            this.dismiss();
          });
        break;
      case 'prenos':
        break;
      case 'preklic':
        this.couponApi.deleteCoupon(this.coupon.couponNumber).subscribe(() => {
          this.dismiss();
        });
        break;
      default:
        break;
    }
  }

  validateEmail(email): boolean {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }
}
