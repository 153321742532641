<div class="header">
  <div></div>
  <img src="./../assets/images/logo-ds.png" />
  <div>
    <div class="logout-btn" *ngIf="loggedIn">
      <button (click)="logout()" class="btn">Odjava</button>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
