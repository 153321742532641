import { Component, OnInit } from '@angular/core';
import { AuthService } from './@core/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'kuponi-darilaslovenije';
  loggedIn: boolean;

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.loggedIn = this.authService.isTokenValid();
    this.authService.isLoggedIn.subscribe((isLoggedIn) => {
      this.loggedIn = isLoggedIn;
    });
  }

  async logout() {
    await this.authService.logout();
  }
}
