import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { WpUserRequest } from '../types/user.types';

@Injectable({ providedIn: 'root' })
export class UserApiService implements UserApiService {
  constructor(private http: HttpClient) {}

  login(username: string, password: string): Observable<WpUserRequest> {
    return this.http.post<WpUserRequest>(
      `${environment.wordpressUrl}/jwt-auth/v1/token`,
      {
        username,
        password,
      }
    );
  }
}
