import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from './auth.service';
@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private auth: AuthService, public router: Router) {}
  async canActivate() {
    const isAuth = this.auth.isTokenValid();
    this.auth.isLoggedIn.next(isAuth);
    if (!isAuth) {
      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }
}
