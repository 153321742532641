import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from '../@core/auth.service';
import { AuthGuardService } from '../@core/auth-guard.service';
import { UserApiService } from './api/user.api';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UnauthGuardService } from '../@core/unauth-guard.service';
import { CouponApiService } from './api/coupon.api';
import { CheckCouponComponent } from './components/check-coupon/check-coupon.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminGuardService } from '../@core/admin-guard.service';
import { CouponDetailsModalComponent } from './components/coupon-details-modal/coupon-details-modal.component';
import { TokenInterceptor } from '../@core/httpInterceptor';

@NgModule({
  imports: [CommonModule, HttpClientModule, FormsModule, ReactiveFormsModule],
  declarations: [CheckCouponComponent, CouponDetailsModalComponent],
  providers: [
    AuthService,
    AuthGuardService,
    UnauthGuardService,
    AdminGuardService,
    UserApiService,
    JwtHelperService,
    CouponApiService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
  exports: [CheckCouponComponent, CouponDetailsModalComponent],
})
export class SharedModule {}
