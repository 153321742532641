import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from './auth.service';
@Injectable()
export class UnauthGuardService implements CanActivate {
  constructor(private auth: AuthService, public router: Router) {}
  async canActivate() {
    const isAuth = this.auth.isTokenValid();
    if (isAuth) {
      this.router.navigate(['/dashboard']);
      return false;
    }
    return true;
  }
}
